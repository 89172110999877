<nav
  class="sb-topnav navbar navbar-expand navbar-dark"
  style="background: linear-gradient(90deg,#002e62,#236bbe);"
>
  <a class="navbar-brand" href="javascript:;"
    ><i class="fa fa-tree"></i> Efoodi Admin</a
  >
  <button class="btn btn-link btn-sm order-1 order-lg-0" id="sidebarToggle">
    <i class="fa fa-bars text-white"></i>
  </button>

  <button
    class="btn btn-link btn-sm order-1 order-lg-0 ml-3"
    appToggleFullscreen
  >
    <i class="fa fa-arrows-alt text-white"></i>
  </button>
  <!-- Navbar Search-->
  <span
    class="d-none d-md-inline-block form-inline ml-auto mr-0 mr-md-3 my-2 my-md-0"
  ></span>
  <!-- Navbar-->
  <ul class="navbar-nav ml-auto ml-md-0">
    <li class="nav-item dropdown mr-3">
      <a
        class="nav-link dropdown-toggle"
        id="userDropdownLang"
        href="#"
        role="button"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
        ><i class="fa fa-language"></i> Language</a>
      <div
        class="dropdown-menu dropdown-menu-right"
        aria-labelledby="userDropdownLang"
      >
        <a class="dropdown-item" href="javascript:;" (click)="switchLanguage('en')"
          ><i class="fa fa-angle-double-right"></i> English</a
        >
        <a class="dropdown-item" href="javascript:;" (click)="switchLanguage('ar')"
          ><i class="fa fa-angle-double-right"></i> Arabic</a
        >
        <a class="dropdown-item" href="javascript:;" (click)="switchLanguage('fr')"
          ><i class="fa fa-angle-double-right"></i> French</a
        >
      </div>
    </li>

    <li class="nav-item">
      <a class="nav-link mr-3" href="javascript:;"
        ><i class="fa fa-bell" aria-hidden="true"></i>
        <span
          style="transform: scale(0.8)"
          class="badge badge-info badge-counter"
          >0</span
        ></a
      >
    </li>
    <li class="nav-item dropdown">
      <a
        class="nav-link dropdown-toggle"
        id="userDropdown"
        href="#"
        role="button"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
        >{{'user' | translate}} <i class="fa fa-user-circle"></i
      ></a>
      <div
        class="dropdown-menu dropdown-menu-right"
        aria-labelledby="userDropdown"
      >
        <a class="dropdown-item" [routerLink]="['/user-profile']"
          ><i class="fa fa-user"></i> {{'profile' | translate}}</a
        >
        <a class="dropdown-item" [routerLink]="['/user-profile']"
          ><i class="fa fa-cog"></i> {{'setting' | translate}}</a
        >
        <div class="dropdown-divider"></div>
        <a
          class="dropdown-item"
          href="javascript:void(0);"
          (click)="userLogout()"
          ><i class="fa fa-sign-out"></i> {{'logout' | translate}}</a
        >
      </div>
    </li>
  </ul>
</nav>
