<main [@fadeInOut]>
  <div class="container-fluid">
    <ol class="breadcrumb mb-4 mt-4">
      <li class="active">User Profile</li>

      <li class="breadcrumb-item ml-auto">
        <a [routerLink]="['/dashboard']"><i class="fa fa-home"></i> Dashboard</a>
      </li>
      <li class="breadcrumb-item">User Profile</li>
    </ol>
    <div class="card shadow-sm mb-4">
      <div class="card-body">
        <div class="row">
          <div class="col-md-12">
            <img src="assets/img/profile-img1.jpg" alt="img" class="img-fluid" style="width: 100%" />
          </div>
          <div class="col-md-4">
            <br />
            <br />
            <table class="table table-striped" *ngIf="(profileData | json) != '{}'">
              <tbody>
                <tr>
                  <td>Emp Name:</td>
                  <td>{{ profileData.aname }}</td>
                </tr>
                <tr>
                  <td>Emp Email:</td>
                  <td>{{ profileData.aemail }}</td>
                </tr>
                <tr>
                  <td>Emp. Mobile:</td>
                  <td>{{ profileData.amobile }}</td>
                </tr>
                <tr>
                  <td>Reg Date:</td>
                  <td>{{ profileData.areg_date | date: "dd/MM/yyyy" }}</td>
                </tr>
                <tr>
                  <td>Status:</td>
                  <td>
                    <span *ngIf="profileData.astatus === '1'" class="badge badge-primary">Active</span>
                    <span *ngIf="profileData.astatus === '2'" class="badge badge-success">In Active</span>
                    <span *ngIf="profileData.astatus === '3'" class="badge badge-danger">Blocked</span>
                  </td>
                </tr>
                <tr>
                  <td>Password:</td>
                  <td>
                    <button type="button" class="btn btn-warning btn-sm" (click)="updatePasswordFun()">
                      Update Password
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="col-md-8">
            <br />
            <h4 class="text-primary">Recent Activity</h4>
            <ul class="list-group">
              <li *ngFor="let actIvity of activityArr"
                class="list-group-item d-flex justify-content-between align-items-center">
                {{ actIvity.employee_action }}
                <span class="badge badge-primary badge-pill">{{
                  actIvity.activity_date | date: "dd/MM/yyyy, h:mm a"
                  }}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</main>

<div class="modal fade" id="passwordModal">
  <div class="modal-dialog modal-sm">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">Update Password</h4>
        <button type="button" class="close" data-dismiss="modal">
          &times;
        </button>
      </div>
      <div class="modal-body">
        <form [formGroup]="updatePassword" (ngSubmit)="updatePassSubmit()">
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <input type="password" class="form-control" formControlName="current_password"
                  placeholder="Current Password" />
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-group">
                <input type="password" class="form-control" formControlName="new_password"
                  placeholder="New Password (Min 5 chars.)" />
              </div>
            </div>
            <div class="col-md-12">
              <button type="submit" [disabled]="!updatePassword.valid" class="btn btn-primary">
                Submit
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>