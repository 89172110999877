import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ToggleFullscreenDirective } from './fullscreen/toggle-fullscreen.directive';
import { ShopOrderDetailComponent } from './shop-order-detail/shop-order-detail.component';

@NgModule({
  imports: [CommonModule],
  declarations: [ShopOrderDetailComponent, ToggleFullscreenDirective],
  exports: [ShopOrderDetailComponent, ToggleFullscreenDirective],
  providers: [],
})
export class SharedModule {}
