<main>
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-6">
          <div class="text-center mt-4">
            <img
              class="mb-4 img-error"
              src="assets/img/error-404-monochrome.svg"
            />
            <p class="lead">This requested URL was not found on this server.</p>
            <a [routerLink]="['/']" style="padding-bottom: 50px">
              <i class="fa fa-home mr-1"></i>
              Return to Dashboard
            </a>
          </div>
        </div>
      </div>
    </div>
  </main>
  