import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-shop-order-detail',
  templateUrl: './shop-order-detail.component.html',
  styleUrls: ['./shop-order-detail.component.css'],
})
export class ShopOrderDetailComponent implements OnInit {
  @Input() singleOrderData: any = {};

  constructor() {}

  ngOnInit(): void {}
}
