import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserAuthService } from '../../appservices/user-auth.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-app-header',
  templateUrl: './app-header.component.html',
  styleUrls: ['./app-header.component.css']
})
export class AppHeaderComponent implements OnInit {
  public selectedLangFlag = 'en';
  public direction = 'ltr';
  constructor(
    public userAuthService: UserAuthService,
    private router: Router,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    if (!localStorage.getItem('hkart_dadmin_lang')) {
      localStorage.setItem('hkart_dadmin_lang', 'en');
    } else {
      this.selectedLangFlag = localStorage.getItem('hkart_dadmin_lang');
      this.switchLanguage(localStorage.getItem('hkart_dadmin_lang'));
    }
  }

  userLogout(): void {
    this.userAuthService.logout();
    this.router.navigate(['/']);
  }

  switchLanguage(language: string): void {
    if (language === 'en') {
      this.selectedLangFlag = 'en';
      this.direction = 'ltr';
      document.dir = this.direction;
    } else if (language === 'ar') {
      this.selectedLangFlag = 'ar';
      this.direction = 'rtl';
      document.dir = this.direction;
    } else {
      this.selectedLangFlag = 'fr';
      this.direction = 'ltr';
      document.dir = this.direction;
    }
    localStorage.setItem('hkart_dadmin_lang', language);
    this.translate.use(language);
  }
}

