<div
  id="layoutAuthentication_content"
  style="background-color: #007bff; height: 100%"
>
  <main>
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-5">
          <div class="card shadow-lg border-0 rounded-lg mt-5">
            <div class="card-header bg_mbz">
              <div class="text-center">
                <img src="assets/img/logo.png" alt="logo" />
              </div>
            </div>
            <div class="card-body">
              <div [innerHTML]="signInMsg" class="text-center"></div>
              <form [formGroup]="signInForm" (ngSubmit)="userSigninSubmit()">
                <div class="form-group">
                  <label class="small mb-1" for="inputEmailAddress"
                    >Username</label
                  >
                  <input
                    class="form-control py-3"
                    type="text"
                    placeholder="Enter Username"
                    formControlName="oduser_email"
                    autocomplete="off"
                  />
                </div>
                <div class="form-group">
                  <label class="small mb-1" for="inputPassword">Password</label>
                  <input
                    class="form-control py-3"
                    type="password"
                    placeholder="Enter Password"
                    formControlName="oduser_password"
                  />
                </div>
                <div
                  class="form-group d-flex align-items-center justify-content-between mt-4 mb-0"
                >
                  <button
                    type="submit"
                    class="btn btn-primary btn-block"
                    [disabled]="!signInForm.valid || isBusyBtn"
                  >
                    Login
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</div>
