import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UserAuthService } from '../appservices/user-auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
  private urlPath;
  public signInForm: FormGroup;
  public signInMsg = '';
  public isBusyBtn = false;

  constructor(
    private authService: UserAuthService,
    private router: Router,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.urlPath = window.location.pathname;
    if (this.urlPath === '/' && this.authService.checkLoggin() === true) {
      this.router.navigate(['/dashboard']);
    }
    this.signInForm = new FormGroup({
      oduser_email: new FormControl(null, [Validators.required]),
      oduser_password: new FormControl(null, [Validators.required]),
    });
  }

  public userSigninSubmit(): void {
    this.isBusyBtn = true;
    this.signInMsg = '';
    this.authService.adminLogin(this.signInForm.value).subscribe(
      (signInRes) => {
        if (signInRes.statusCode === 'OK') {
          this.signInMsg = `<p class="text-primary margin0">${signInRes.message}</p>`;
          this.authService.storeUserData(
            signInRes.payload.auth_token,
            signInRes.payload.adminData
          );
          this.router.navigate(['/dashboard']);
        } else {
          this.signInMsg = `<p class="text-danger margin0">${signInRes.message}</p>`;
        }
        this.signInForm.reset();
        this.isBusyBtn = false;
      },
      (error) => {
        this.isBusyBtn = false;
        this.toastr.error(error, 'Error');
      }
    );
  }
}
