import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LoginComponent } from './login/login.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { AppFooterComponent } from './layout/app-footer/app-footer.component';
import { AppHeaderComponent } from './layout/app-header/app-header.component';
import { AppLayoutComponent } from './layout/app-layout/app-layout.component';
import { AppSidebarComponent } from './layout/app-sidebar/app-sidebar.component';

import { AuthGuard } from './appservices/auth.guard';
import { RoleGuardGuard } from './appservices/role-guard.guard';

const routes: Routes = [
  { path: '', component: LoginComponent, pathMatch: 'full' },
  {
    path: '',
    component: AppLayoutComponent,
    children: [
      {
        path: 'dashboard',
        component: DashboardComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'user-profile',
        component: UserProfileComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'account-section',
        loadChildren: () =>
          import('./account-section/account-section.module').then(
            (m) => m.AccountSectionModule
          ),
        canActivate: [RoleGuardGuard],
        data: { expectedRole: '3' },
      },
      {
        path: 'data-admin',
        loadChildren: () =>
          import('./data-manager-admin/data-manager-admin.module').then(
            (m) => m.DataManagerAdminModule
          ),
        canActivate: [RoleGuardGuard],
        data: { expectedRole: '1' },
      },
      {
        path: 'data-emp',
        loadChildren: () =>
          import('./data-manager-emp/data-manager-emp.module').then(
            (m) => m.DataManagerEmpModule
          ),
        canActivate: [RoleGuardGuard],
        data: { expectedRole: '2' },
      },
      {
        path: 'rest-common',
        loadChildren: () =>
          import('./rest-common/rest-common.module').then(
            (m) => m.RestCommonModule
          ),
        canActivate: [RoleGuardGuard],
        data: { expectedRole: '4' },
      },
      {
        path: 'store-common',
        loadChildren: () =>
          import('./store-common/store-common.module').then(
            (m) => m.StoreCommonModule
          ),
        canActivate: [RoleGuardGuard],
        data: { expectedRole: '4' },
      },
      {
        path: 'lead-section',
        loadChildren: () =>
          import('./lead-section/lead-section.module').then(
            (m) => m.LeadSectionModule
          ),
        canActivate: [RoleGuardGuard],
        data: { expectedRole: '5' },
      },
      {
        path: 'rider-section',
        loadChildren: () =>
          import('./rider-section/rider-section.module').then(
            (m) => m.RiderSectionModule
          ),
        canActivate: [RoleGuardGuard],
        data: { expectedRole: '6' },
      }
    ],
  },
  { path: '**', component: NotFoundComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }

export const routingComp = [
  LoginComponent,
  NotFoundComponent,
  UserProfileComponent,
  DashboardComponent,
  AppFooterComponent,
  AppHeaderComponent,
  AppLayoutComponent,
  AppSidebarComponent,
];
