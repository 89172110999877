<div id="layoutSidenav_nav">
  <nav class="sb-sidenav accordion sb-sidenav-dark" id="sidenavAccordion">
    <div class="sb-sidenav-menu">
      <div class="nav">
        <br />
        <a class="nav-link" [routerLink]="['/dashboard']" [routerLinkActive]="['active']">
          <div class="sb-nav-link-icon">
            <i class="fa fa-tachometer"></i>
          </div>
          {{'sideMenu.dashboard' | translate}}
        </a>
        <!-- <div class="sb-sidenav-menu-heading">Super Admin Section</div> -->
        <ng-container *ngIf="chkMainRolesSideBar('3')">
          <a class="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseAccntSec"
            aria-expanded="false" aria-controls="collapseAccntSec">
            <div class="sb-nav-link-icon">
              <i class="fa fa-calculator" aria-hidden="true"></i>
            </div>
            {{'sideMenu.accountsSection' | translate}}
            <div class="sb-sidenav-collapse-arrow">
              <i class="fa fa-angle-down"></i>
            </div>
          </a>
          <div class="collapse" id="collapseAccntSec" aria-labelledby="headingOne" data-parent="#sidenavAccordion">
            <nav class="sb-sidenav-menu-nested nav">
              <a *ngIf="chkSubRolesSideBar('3.1')" class="nav-link"
                [routerLink]="['/account-section', 'restaurants-docs']" [routerLinkActive]="['active']"><i
                  class="fa fa-angle-double-right"></i>&nbsp;{{'sideMenu.restaurantDocuments' | translate}}</a>
              <a *ngIf="chkSubRolesSideBar('3.1')" class="nav-link" [routerLink]="['/account-section', 'store-docs']"
                [routerLinkActive]="['active']"><i
                  class="fa fa-angle-double-right"></i>&nbsp;{{'sideMenu.storeDocuments' | translate}}</a>
            </nav>
          </div>
        </ng-container>
        <ng-container *ngIf="chkMainRolesSideBar('1')">
          <a class="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseDataAdm"
            aria-expanded="false" aria-controls="collapseDataAdm">
            <div class="sb-nav-link-icon">
              <i class="fa fa-database" aria-hidden="true"></i>
            </div>
            {{'sideMenu.dataManagerAdmin' | translate}}
            <div class="sb-sidenav-collapse-arrow">
              <i class="fa fa-angle-down"></i>
            </div>
          </a>
          <div class="collapse" id="collapseDataAdm" aria-labelledby="headingOne" data-parent="#sidenavAccordion">
            <nav class="sb-sidenav-menu-nested nav">
              <!-- <a *ngIf="chkSubRolesSideBar('1.1')" class="nav-link" [routerLink]="['/data-admin', 'add-vendor']"
                [routerLinkActive]="['active']"><i class="fa fa-angle-double-right"></i>&nbsp;{{'sideMenu.addNewVendor'
                | translate}}</a> -->
              <!-- <a *ngIf="chkSubRolesSideBar('1.2')" class="nav-link" [routerLink]="['/data-admin', 'vendor-list']"
                [routerLinkActive]="['active']"><i
                  class="fa fa-angle-double-right"></i>&nbsp;{{'sideMenu.restaurantVendorList' | translate}}</a>
              <a *ngIf="chkSubRolesSideBar('1.3')" class="nav-link" [routerLink]="['/data-admin', 'restaurant-list']"
                [routerLinkActive]="['active']"><i
                  class="fa fa-angle-double-right"></i>&nbsp;{{'sideMenu.restaurantList' | translate}}</a> -->
              <!-- <a *ngIf="chkSubRolesSideBar('1.4')" class="nav-link"
                [routerLink]="['/data-admin', 'restaurant-master-user']" [routerLinkActive]="['active']"><i
                  class="fa fa-angle-double-right"></i>&nbsp;{{'sideMenu.restaurantMasterUser' | translate}}</a>
              <a *ngIf="chkSubRolesSideBar('1.5')" class="nav-link" [routerLink]="['/data-admin', 'filter-vendor-data']"
                [routerLinkActive]="['active']"><i class="fa fa-angle-double-right"></i>&nbsp;{{'sideMenu.filterVendor'
                | translate}}</a>
              <a *ngIf="chkSubRolesSideBar('1.6')" class="nav-link"
                [routerLink]="['/data-admin', 'export-restaurant-data']" [routerLinkActive]="['active']"><i
                  class="fa fa-angle-double-right"></i>&nbsp;{{'sideMenu.exportRestaurantData' | translate}}</a> -->

              <a *ngIf="chkSubRolesSideBar('1.6')" class="nav-link" [routerLink]="['/data-admin', 'add-store-vendor']"
                [routerLinkActive]="['active']"><i
                  class="fa fa-angle-double-right"></i>&nbsp;{{'sideMenu.addNewStoreVendor' | translate}}</a>
              <a *ngIf="chkSubRolesSideBar('1.6')" class="nav-link" [routerLink]="['/data-admin', 'store-vendor-list']"
                [routerLinkActive]="['active']"><i
                  class="fa fa-angle-double-right"></i>&nbsp;{{'sideMenu.storeVendorList' | translate}}</a>
              <a *ngIf="chkSubRolesSideBar('1.6')" class="nav-link" [routerLink]="['/data-admin', 'store-list']"
                [routerLinkActive]="['active']"><i class="fa fa-angle-double-right"></i>&nbsp;{{'sideMenu.storeList' |
                translate}}</a>
              <a *ngIf="chkSubRolesSideBar('1.6')" class="nav-link" [routerLink]="['/data-admin', 'store-master-user']"
                [routerLinkActive]="['active']"><i
                  class="fa fa-angle-double-right"></i>&nbsp;{{'sideMenu.storeMasterUser' | translate}}</a>
              <a *ngIf="chkSubRolesSideBar('1.6')" class="nav-link" [routerLink]="['/data-admin', 'export-store-data']"
                [routerLinkActive]="['active']"><i
                  class="fa fa-angle-double-right"></i>&nbsp;{{'sideMenu.exportStoreData' | translate}}</a>
            </nav>
          </div>
        </ng-container>

        <ng-container *ngIf="chkMainRolesSideBar('2')">
          <a class="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseDataEmp"
            aria-expanded="false" aria-controls="collapseDataEmp">
            <div class="sb-nav-link-icon">
              <i class="fa fa-archive" aria-hidden="true"></i>
            </div>
            {{'sideMenu.dataManagerEmp' | translate}}
            <div class="sb-sidenav-collapse-arrow">
              <i class="fa fa-angle-down"></i>
            </div>
          </a>
          <div class="collapse" id="collapseDataEmp" aria-labelledby="headingOne" data-parent="#sidenavAccordion">
            <nav class="sb-sidenav-menu-nested nav">
              <a *ngIf="chkSubRolesSideBar('2.1')" class="nav-link" [routerLink]="['/data-emp', 'add-vendor']"
                [routerLinkActive]="['active']"><i class="fa fa-angle-double-right"></i>&nbsp;{{'sideMenu.addNewVendor'
                | translate}}</a>
              <a *ngIf="chkSubRolesSideBar('2.2')" class="nav-link" [routerLink]="['/data-emp', 'vendor-list']"
                [routerLinkActive]="['active']"><i
                  class="fa fa-angle-double-right"></i>&nbsp;{{'sideMenu.restaurantVendorList' | translate}}</a>
              <a *ngIf="chkSubRolesSideBar('2.3')" class="nav-link" [routerLink]="['/data-emp', 'restaurant-list']"
                [routerLinkActive]="['active']"><i
                  class="fa fa-angle-double-right"></i>&nbsp;{{'sideMenu.restaurantList' | translate}}</a>
                  <!-- <a *ngIf="chkSubRolesSideBar('1.3')" class="nav-link" [routerLink]="['/data-admin', 'restaurant-list']"
                [routerLinkActive]="['active']"><i
                  class="fa fa-angle-double-right"></i>&nbsp;{{'sideMenu.restaurantList' | translate}}</a> -->
              <!-- <a *ngIf="chkSubRolesSideBar('1.4')" class="nav-link"
                [routerLink]="['/data-admin', 'restaurant-master-user']" [routerLinkActive]="['active']"><i
                  class="fa fa-angle-double-right"></i>&nbsp;{{'sideMenu.restaurantMasterUser' | translate}}</a> -->
              <!-- <a *ngIf="chkSubRolesSideBar('1.5')" class="nav-link" [routerLink]="['/data-admin', 'filter-vendor-data']"
                [routerLinkActive]="['active']"><i class="fa fa-angle-double-right"></i>&nbsp;{{'sideMenu.filterVendor'
                | translate}}</a> -->
              
              <a *ngIf="chkSubRolesSideBar('2.4')" class="nav-link" [routerLink]="['/data-emp', 'main-menu-by-file']"
                [routerLinkActive]="['active']"><i
                  class="fa fa-angle-double-right"></i>&nbsp;{{'sideMenu.mainMenuByFile' | translate}}</a>
              <a *ngIf="chkSubRolesSideBar('2.5')" class="nav-link" [routerLink]="['/data-emp', 'dishes-by-file']"
                [routerLinkActive]="['active']"><i class="fa fa-angle-double-right"></i>&nbsp;{{'sideMenu.dishesByFile'
                | translate}}</a>
                <a *ngIf="chkSubRolesSideBar('1.6')" class="nav-link"
                [routerLink]="['/data-admin', 'export-restaurant-data']" [routerLinkActive]="['active']"><i
                  class="fa fa-angle-double-right"></i>&nbsp;{{'sideMenu.exportRestaurantData' | translate}}</a>
            </nav>
          </div>
        </ng-container>

        <ng-container *ngIf="chkMainRolesSideBar('4')">
          <a class="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseRestCommon"
            aria-expanded="false" aria-controls="collapseRestCommon">
            <div class="sb-nav-link-icon">
              <i class="fa fa-cutlery" aria-hidden="true"></i>
            </div>
            {{'sideMenu.restaurantCommon' | translate}}
            <div class="sb-sidenav-collapse-arrow">
              <i class="fa fa-angle-down"></i>
            </div>
          </a>
          <div class="collapse" id="collapseRestCommon" aria-labelledby="headingOne" data-parent="#sidenavAccordion">
            <nav class="sb-sidenav-menu-nested nav">
              <a *ngIf="chkSubRolesSideBar('4.1')" class="nav-link" [routerLink]="['/rest-common', 'popular-brand']"
                [routerLinkActive]="['active']"><i class="fa fa-angle-double-right"></i>&nbsp;{{'sideMenu.popularBrands'
                | translate}}</a>
              <a *ngIf="chkSubRolesSideBar('4.2')" class="nav-link"
                [routerLink]="['/rest-common', 'premium-restaurant']" [routerLinkActive]="['active']"><i
                  class="fa fa-angle-double-right"></i>&nbsp;{{'sideMenu.premiumRestaurant' | translate}}</a>
              <a *ngIf="chkSubRolesSideBar('4.3')" class="nav-link" [routerLink]="['/rest-common', 'rest-item-price']"
                [routerLinkActive]="['active']"><i class="fa fa-angle-double-right"></i>&nbsp;{{'sideMenu.restItemPrice'
                | translate}}</a>
              <a *ngIf="chkSubRolesSideBar('4.4')" class="nav-link" [routerLink]="['/rest-common', 'rest-all-offers']"
                [routerLinkActive]="['active']"><i class="fa fa-angle-double-right"></i>&nbsp;{{'sideMenu.restAllOffers'
                | translate}}</a>
            </nav>
          </div>
        </ng-container>

        <ng-container *ngIf="chkMainRolesSideBar('4')">
          <a class="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseStoreCommon"
            aria-expanded="false" aria-controls="collapseStoreCommon">
            <div class="sb-nav-link-icon">
              <i class="fa fa-shopping-basket" aria-hidden="true"></i>
            </div>
            {{'sideMenu.storeCommon' | translate}}
            <div class="sb-sidenav-collapse-arrow">
              <i class="fa fa-angle-down"></i>
            </div>
          </a>
          <div class="collapse" id="collapseStoreCommon" aria-labelledby="headingOne" data-parent="#sidenavAccordion">
            <nav class="sb-sidenav-menu-nested nav">
              <a *ngIf="chkSubRolesSideBar('4.1')" class="nav-link" [routerLink]="['/store-common', 'product-units']"
                [routerLinkActive]="['active']"><i class="fa fa-angle-double-right"></i>&nbsp;{{'sideMenu.productUnit'
                | translate}}</a>
              <a *ngIf="chkSubRolesSideBar('4.2')" class="nav-link" [routerLink]="['/store-common', 'main-cat-master']"
                [routerLinkActive]="['active']"><i class="fa fa-angle-double-right"></i>&nbsp;{{'sideMenu.storeMainCat'
                | translate}}</a>
              <a *ngIf="chkSubRolesSideBar('4.3')" class="nav-link" [routerLink]="['/store-common', 'sub-cat-master']"
                [routerLinkActive]="['active']"><i class="fa fa-angle-double-right"></i>&nbsp;{{'sideMenu.storeSubCat'
                | translate}}</a>
              <a *ngIf="chkSubRolesSideBar('4.4')" class="nav-link"
                [routerLink]="['/store-common', 'add-master-product']" [routerLinkActive]="['active']"><i
                  class="fa fa-angle-double-right"></i>&nbsp;{{'sideMenu.storeMasterProduct'
                | translate}}</a>
              <a *ngIf="chkSubRolesSideBar('4.4')" class="nav-link"
                [routerLink]="['/store-common', 'master-product-list']" [routerLinkActive]="['active']"><i
                  class="fa fa-angle-double-right"></i>&nbsp;{{'sideMenu.storeMasterProDuctList'
                | translate}}</a>
            </nav>
          </div>
        </ng-container>

        <ng-container *ngIf="chkMainRolesSideBar('5')">
          <a class="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseLeadSec"
            aria-expanded="false" aria-controls="collapseLeadSec">
            <div class="sb-nav-link-icon">
              <i class="fa fa-handshake-o" aria-hidden="true"></i>
            </div>
            {{'sideMenu.leadSection' | translate}}
            <div class="sb-sidenav-collapse-arrow">
              <i class="fa fa-angle-down"></i>
            </div>
          </a>
          <div class="collapse" id="collapseLeadSec" aria-labelledby="headingOne" data-parent="#sidenavAccordion">
            <nav class="sb-sidenav-menu-nested nav">
              <a *ngIf="chkSubRolesSideBar('5.1')" class="nav-link" [routerLink]="['/lead-section', 'restaurant-leads']"
                [routerLinkActive]="['active']"><i class="fa fa-angle-double-right"></i>&nbsp;{{'sideMenu.restLead' |
                translate}}</a>
            </nav>
          </div>
        </ng-container>

        <ng-container *ngIf="chkMainRolesSideBar('6')">
          <a class="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseRiderSec"
            aria-expanded="false" aria-controls="collapseRiderSec">
            <div class="sb-nav-link-icon">
              <i class="fa fa-motorcycle" aria-hidden="true"></i>
            </div>
            {{'sideMenu.riderSection' | translate}}
            <div class="sb-sidenav-collapse-arrow">
              <i class="fa fa-angle-down"></i>
            </div>
          </a>
          <div class="collapse" id="collapseRiderSec" aria-labelledby="headingOne" data-parent="#sidenavAccordion">
            <nav class="sb-sidenav-menu-nested nav">
              <a *ngIf="chkSubRolesSideBar('6.1')" class="nav-link" [routerLink]="['/rider-section', 'add-new-rider']"
                [routerLinkActive]="['active']"><i class="fa fa-angle-double-right"></i>&nbsp;{{'sideMenu.addNewRider' |
                translate}}</a>
              <a *ngIf="chkSubRolesSideBar('6.2')" class="nav-link" [routerLink]="['/rider-section', 'rider-list']"
                [routerLinkActive]="['active']"><i class="fa fa-angle-double-right"></i>&nbsp;{{'sideMenu.riderList' |
                translate}}</a>
              <a *ngIf="chkSubRolesSideBar('6.3')" class="nav-link" [routerLink]="['/rider-section', 'rider-location']"
                [routerLinkActive]="['active']"><i class="fa fa-angle-double-right"></i>&nbsp;{{'sideMenu.riderLocation'
                | translate}}</a>
            </nav>
          </div>
        </ng-container>


      </div>
    </div>
  </nav>
</div>