<main [@fadeInOut]>
  <div class="container-fluid">
    <ol class="breadcrumb mb-4 mt-4">
      <li class="active">Dashboard</li>

      <li class="breadcrumb-item ml-auto">
        <a [routerLink]="['/dashboard']"><i class="fa fa-home"></i> Dashboard</a>
      </li>
    </ol>

    <h3 class="mt-4">Dashboard</h3>
    <div class="row">
      <div class="col-md-12 text-center">
        <img class="mx-auto img-fluid mt-5 shadow-dash" src="assets/img/work-progress.gif" alt="Img" />
        <h3 class="mt-5 mb-5">
          -- Work in Progress --
        </h3>
      </div>
    </div>

  </div>
</main>