import { Component, OnInit } from '@angular/core';
import decode from 'jwt-decode';
declare let $: any;

@Component({
  selector: 'app-app-sidebar',
  templateUrl: './app-sidebar.component.html',
  styleUrls: ['./app-sidebar.component.css']
})
export class AppSidebarComponent implements OnInit {
  public mainRolesArray = [];
  public subRolesArray = [];
  constructor() {
    const token = localStorage.getItem('hkart_dadmin_token');
    if (token) {
      const tokenPayload: any = decode(token);
      this.mainRolesArray = tokenPayload.main_role;
      this.subRolesArray = tokenPayload.sub_role;
    } else {
      this.mainRolesArray = [];
      this.subRolesArray = [];
    }
  }

  ngOnInit(): void {
    $(document).ready(() => {
      const path = window.location.href;
      $('#layoutSidenav_nav .sb-sidenav a.nav-link').each(function() {
        if (this.href === path) {
          $(this).addClass('active');
        }
      });

      // Toggle the side navigation
      // tslint:disable-next-line: only-arrow-functions
      $('#sidebarToggle').on('click', function(e) {
        e.preventDefault();
        $('body').toggleClass('sb-sidenav-toggled');
      });
    });
  }

  chkMainRolesSideBar(rid): boolean {
    return this.mainRolesArray.indexOf(rid) > -1;
  }
  chkSubRolesSideBar(rid): boolean {
    return this.subRolesArray.indexOf(rid) > -1;
  }
}
