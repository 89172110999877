<div class="modal fade" id="orderDetailModal">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">Order Detail</h4>
        <button type="button" class="close" data-dismiss="modal">
          &times;
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-12">
            <div class="card" *ngIf="singleOrderData">
              <div class="card-header">
                <div class="row">
                  <div class="col-md-6">
                    Order Id: <strong> #{{ singleOrderData.order_no }} </strong>
                  </div>

                  <div class="col-md-6">
                    <span class="float-right">
                      Status:
                      <span class="badge badge-success">{{
                        singleOrderData.order_current_status
                      }}</span>
                    </span>
                  </div>
                </div>
              </div>
              <div class="card-body">
                <div class="row mb-4">
                  <div class="col-sm-4 bd-right">
                    <p>
                      <strong>Name:</strong> {{ singleOrderData.user_name }}
                    </p>
                    <p>
                      <strong>Mobile:</strong> {{ singleOrderData.user_mobile }}
                    </p>
                    <p>
                      <strong>Payment:</strong>
                      {{ singleOrderData.payment_by | uppercase }}
                    </p>
                    <p>
                      <strong>Pay. Mode :</strong>
                      {{ singleOrderData.payment_method | uppercase }}
                    </p>
                    <p>
                      <strong>Order By:</strong>
                      {{ singleOrderData.order_from }}
                    </p>
                  </div>
                  <div class="col-sm-8">
                    <h6 class="mb-3">
                      User Zone: {{ singleOrderData.order_zone }}
                    </h6>
                    <h6 class="mb-2">User Address:</h6>
                    <p>{{ singleOrderData.user_address }}</p>
                  </div>
                </div>
                <hr />
                <div class="row mb-4">
                  <div class="col-sm-9">
                    <p>
                      <strong>Shop Name:</strong>
                      {{ singleOrderData.shop_name }}
                    </p>
                    <p>
                      <strong>Shop Phone:</strong>
                      {{ singleOrderData.shop_id.shop_phone }}
                    </p>
                    <p>
                      <strong>Shop Add.:</strong>
                      {{ singleOrderData.shop_address }}
                    </p>
                  </div>
                  <div class="col-sm-3" style="background-color: #8de4e7">
                    <p><strong>Delivery Boy:</strong></p>
                    <p *ngIf="singleOrderData.rider_id">
                      {{ singleOrderData.rider_id.rider_name }}
                    </p>
                    <p *ngIf="singleOrderData.rider_id">
                      {{ singleOrderData.rider_id.rider_mobile }}
                    </p>
                  </div>
                </div>
                <div class="table-responsive">
                  <table class="table table-striped">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Item</th>
                        <th>Cost User</th>
                        <th>Cost Vendor</th>
                        <th>Qty</th>
                        <th>Total User</th>
                        <th>Total Vendor</th>
                        <th
                          *ngIf="
                            singleOrderData.order_current_status === 'DELIVERED'
                          "
                        >
                          Vendor Chk
                        </th>
                        <th
                          *ngIf="
                            singleOrderData.order_current_status === 'DELIVERED'
                          "
                        >
                          Delivery Chk
                        </th>
                        <th
                          *ngIf="
                            singleOrderData.order_current_status === 'DELIVERED'
                          "
                        >
                          User Chk
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        *ngFor="
                          let order_item of singleOrderData.order_items;
                          let j = index
                        "
                      >
                        <td class="center">{{ j + 1 }}</td>
                        <td class="left strong">
                          {{ order_item.product_name }} -
                          {{ order_item.varient_name }}
                        </td>
                        <td class="right">
                          {{
                            order_item.product_selling_price | currency: "INR"
                          }}
                        </td>
                        <td class="right">
                          {{
                            order_item.product_vendor_price | currency: "INR"
                          }}
                        </td>
                        <td class="center">{{ order_item.quantity }}</td>
                        <td class="right">
                          {{
                            order_item.product_selling_price *
                              order_item.quantity | currency: "INR"
                          }}
                        </td>
                        <td class="right">
                          {{
                            order_item.product_vendor_price *
                              order_item.quantity | currency: "INR"
                          }}
                        </td>
                        <td
                          *ngIf="
                            singleOrderData.order_current_status === 'DELIVERED'
                          "
                        >
                          <span
                            *ngIf="order_item.avl_chk_vendor === '1'"
                            class="badge badge-primary"
                            >Available</span
                          >
                          <span
                            *ngIf="order_item.avl_chk_vendor === '2'"
                            class="badge badge-danger"
                            >Not Available</span
                          >
                        </td>
                        <td
                          *ngIf="
                            singleOrderData.order_current_status === 'DELIVERED'
                          "
                        >
                          <span
                            *ngIf="order_item.accept_chk_rider === '1'"
                            class="badge badge-success"
                            >Received</span
                          >
                          <span
                            *ngIf="order_item.accept_chk_rider === '2'"
                            class="badge badge-danger"
                            >Not Received</span
                          >
                        </td>
                        <td
                          *ngIf="
                            singleOrderData.order_current_status === 'DELIVERED'
                          "
                        >
                          <span
                            *ngIf="order_item.delivery_chk_rider === '1'"
                            class="badge badge-warning"
                            >Delivered</span
                          >
                          <span
                            *ngIf="order_item.delivery_chk_rider === '2'"
                            class="badge badge-danger"
                            >Not Delivered</span
                          >
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-6 col-sm-6">
            <table class="table table-clear">
              <tbody>
                <tr>
                  <td colspan="2">
                    <strong
                      >Promocode - ({{ singleOrderData.promo_code }} &nbsp;
                      {{ singleOrderData.promo_code_text }}),</strong
                    >
                    -
                    {{ singleOrderData.promo_amount | currency: "INR" }}
                  </td>
                </tr>
                <tr>
                  <td colspan="2">
                    <strong>Hungrykart Money: </strong>
                    -
                    {{ singleOrderData.normal_wallet_amount | currency: "INR" }}
                  </td>
                </tr>
                <tr>
                  <td colspan="2">
                    <strong>Hungrykart Bonus:</strong>
                    -
                    {{ singleOrderData.bonus_wallet_amount | currency: "INR" }}
                  </td>
                </tr>
                <tr>
                  <td colspan="2">
                    <strong>Shop Offer:</strong>
                    {{ singleOrderData.shop_percent_off }} (-{{
                      singleOrderData.shop_percent_offamt | currency: "INR"
                    }})
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="col-lg-6 col-sm-6">
            <table class="table table-clear">
              <tbody>
                <tr>
                  <td class="left">
                    <strong>Taxes</strong>
                  </td>
                  <td class="right">
                    <strong>{{
                      singleOrderData.tax_amount | currency: "INR"
                    }}</strong>
                  </td>
                </tr>
                <tr>
                  <td class="left">
                    <strong>Delivery Charge.</strong>
                  </td>
                  <td class="right">
                    <strong>{{
                      singleOrderData.delivery_charge | currency: "INR"
                    }}</strong>
                  </td>
                </tr>
                <!-- <tr>
                  <td class="left">
                    <strong>packaging Charge:</strong>
                  </td>
                  <td class="right">
                    <strong>{{
                      singleOrderData.packaging_charges
                        | currency: 'INR'
                    }}</strong>
                  </td>
                </tr> -->
                <tr>
                  <td class="left">
                    <strong>Night Charge:</strong>
                  </td>
                  <td class="right">
                    <strong>{{
                      singleOrderData.night_charge | currency: "INR"
                    }}</strong>
                  </td>
                </tr>
                <tr *ngFor="let extCharg of singleOrderData.extra_charges">
                  <td class="left">
                    <strong>{{ extCharg.ext_charge_name }}:</strong>
                  </td>
                  <td class="right">
                    <strong>{{
                      extCharg.ext_charge_amt | currency: "INR"
                    }}</strong>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4 col-sm-12" style="background-color: aqua">
            <p>
              <strong>Item Total Before Dis.: </strong>
              {{ singleOrderData.item_total_before_discount | currency: "INR" }}
            </p>
          </div>
          <div class="col-md-4 col-sm-12" style="background-color: aquamarine">
            <p>
              <strong>Item Total After Dis.: </strong>
              {{ singleOrderData.item_total_after_discount | currency: "INR" }}
            </p>
          </div>
          <div
            class="col-md-4 col-sm-12"
            style="background-color: darkturquoise"
          >
            <p>
              <strong>Total Payable Amt: </strong>
              {{ singleOrderData.total_payble_amount | currency: "INR" }}
            </p>
          </div>
        </div>
        <hr />
        <p *ngIf="singleOrderData.order_note">
          <strong>Order Note:</strong> {{ singleOrderData.order_note }}
        </p>
        <hr />
        <div class="timeline" *ngIf="singleOrderData">
          <ul class="timeline">
            <li *ngFor="let step of singleOrderData.order_process_timeline">
              <p>
                <span class="text-success mr-2">{{ step.process_time }},</span>
                <span class="text-primary">
                  <strong>{{ step.process_cmt }}</strong>
                </span>
              </p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
