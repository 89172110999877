import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { UserAuthService } from '../appservices/user-auth.service';
import decode from 'jwt-decode';

@Injectable({
  providedIn: 'root',
})
export class RoleGuardGuard implements CanActivate {
  constructor(public auth: UserAuthService, public router: Router) {}
  canActivate(route: ActivatedRouteSnapshot): boolean {
    const expectedRole = route.data.expectedRole;
    const token = localStorage.getItem('hkart_dadmin_token');
    if (token === null || token === '' || token === undefined) {
      this.router.navigate(['/']);
      return false;
    } else {
      const tokenPayload: any = decode(token);
      if (!tokenPayload.main_role.some((x) => x === expectedRole)) {
        this.router.navigate(['/']);
        return false;
      }
      return true;
    }
  }
}
